<template>
    <v-dialog
      overlay-color="primary"
      overlay-opacity=".97"
      persistent
      :fullscreen="this.$vuetify.breakpoint.smAndUp  ? false: true"
      :max-width="'60%'"
      v-model="bActive"
      v-if="bActive">
      <v-card
        class="dialog_container">
        <v-layout
          row
          justify-end>
          <v-tooltip
            bottom>
            <template
              v-slot:activator="{ on }"
            >
              <span
                style="position: fixed; z-index: 100"
                v-on="on">
                <v-btn
                  fab
                  small
                  color="primary"
                  dark
                  @click="close"
                  >
                  <v-icon
                    v-html="'mdi-close'"
                  />
                </v-btn>
              </span>
            </template>
            <span
              v-html="'Cerrar'"
            />
          </v-tooltip>
        </v-layout>
        <v-container>
          <v-layout
            style="margin-top: 10px; margin-bottom: 10px"
            row
            justify-center>
            <h1
              class="client_edit_title"
              v-html="'Agregar una imágen'"
            />
          </v-layout>
          <file-pond
            id="filePond"
            v-model="myFiles"
            dark
            :server="getServer()"
            color="primary"
            name="file"
            ref="pond"
            label-idle="Elegir archivo o arrastrar archivo aqui"
            allow-multiple="true"
            accepted-file-types="image/jpeg, image/png, , image/jpg"
            v-on:init="handleFilePondInit"
          />
        </v-container>
      </v-card>
    </v-dialog>
</template>
<script src="https://unpkg.com/filepond-plugin-image-preview"></script>
<script src="https://unpkg.com/filepond"></script>
<script src="https://unpkg.com/vue-filepond"></script>
<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
    props: {
      projectId: String,
      bActive: Boolean,
      usr_token: String
    },
    data: () => ({
      myFiles: [''],
      count: 0
    }),
    watch: {
      myFiles: function (e) {
        var files = e
        if (typeof files !== 'undefined' && files.length > 0) {
          if (this.count == 0) {
            this.count += 1
            this.uploadFiles(files)
          }
        }
      }
    },
    methods: {
      uploadFiles (files) {
        const count = 0
        files.forEach(file => {
          count += 1
          var formData = new FormData()
          formData.append('file', file)
            db.post(`${uri}/api/v1/projects/${this.projectId}/image`, formData, {
            headers: {
              'Authorization': this.usr_token,
              'Content-Type': 'multipart/form-data'
            },
            params: {
              projectId: this.projectId
            }
          })
          .then((response) => {
            const alert = {
              color: 'success',
              text: response.data.message
            }
            this.$store.commit('toggle_alert', alert + 'Foto: ' + count)
            this.close()
          })
          .catch((error) => {
            if (typeof error !== 'undefined') {
              const alert = {
                color: 'fail',
                text: error.response.data.message
              }
              this.$store.commit('toggle_alert',  alert)
            }
          })
        })
      },
      getServer () {
        var server = {
            process:(fieldName, file, metadata, load, error, progress, abort) => {
                const formData = new FormData()
                formData.append(fieldName, file, file.name)
                  db.post(`${uri}/api/v1/projects/${this.projectId}/image`, formData, {
                  headers: {
                    'Authorization': this.usr_token,
                    'Content-Type': 'multipart/form-data'
                  },
                  params: {
                    projectId: this.projectId
                  }
                })
                .then((response) => {
                  const alert = {
                    color: 'success',
                    text: response.data.message
                  }
                  this.$store.commit('toggle_alert', alert)
                  this.close()
                  abort()
                })
                .catch((error) => {
                  if (typeof error !== 'undefined') {
                    const alert = {
                      color: 'fail',
                      text: error.response.data.message
                    }
                    this.$store.commit('toggle_alert',  alert)
                    abort()
                  }
                })
            }
        }
        return server
      },
      handleFilePondInit: function(a) {
      },
      close () {
        this.$emit('close')
      }
    },
    components: {
        FilePond
    }
  }
</script>
<style>
  .dialog_container {
    background-color: #21345C !important
  }
  .filepond--browser {
    background-color: #21345C;
    color: white
  }
  .filepond--panel-bottom {
    background-color: #21345C;
    color: white
  }
  .filepond--panel-center {
    background-color: #21345C;
    color: white
  }
  .filepond--drop-label {
    background-color: #21345C;
    color: white
  }
  .image_edit_container {
    margin: 100px
  }
  .client_edit_title {
    margin-top: 5px;
    font-family: 'Open sans', 'sans-serif';
    text-align: center;
    color: white !important
  }
</style>
